import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from './company.state';

export const companyFeatureKey = 'company';
export const selectCompanyFeature = createFeatureSelector<CompanyState>(companyFeatureKey);
export const selectAllCompanies = createSelector(
  selectCompanyFeature,
  (companyState: CompanyState) => companyState.companies
);
export const selectCompany = createSelector(
  selectCompanyFeature,
  (companyState: CompanyState) => companyState.company
);
export const selectLoadingCompany = createSelector(
  selectCompanyFeature,
  (companyState: CompanyState) => companyState.loadingCompany
);
export const selectCompanyUpdated = createSelector(
  selectCompanyFeature,
  (companyState: CompanyState) => companyState.companyUpdated
);
export const selectCompanyDeleted = createSelector(
  selectCompanyFeature,
  (companyState: CompanyState) => companyState.companyDeleted
);
export const selectCreateByName = createSelector(
  selectCompanyFeature,
  (companyState: CompanyState) => companyState.createByName
);
export const selectCreateByEmail = createSelector(
  selectCompanyFeature,
  (companyState: CompanyState) => companyState.createByEmail
);
